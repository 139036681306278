import {
  WIDGET_TOKEN_REQUEST_PENDING,
  WIDGET_TOKEN_REQUEST_RECEIVED,
  WIDGET_TOKEN_REQUEST_ERROR,
  WIDGET_TOKEN_REQUEST_RESET,
} from '../actions/open-banking/open-banking-action-types';

const defaultWidgetState = {
  token: null,
  tokenStatus: null,
  error: false,
};

export const widgetAccessToken = (state = defaultWidgetState, action) => {
  switch (action.type) {
    case WIDGET_TOKEN_REQUEST_PENDING: {
      return {
        ...state,
        token: null,
        tokenStatus: 'pending',
        error: false,
      };
    }
    case WIDGET_TOKEN_REQUEST_RECEIVED: {
      const { accessToken } = action.payload;
      return {
        ...state,
        token: accessToken,
        tokenStatus: 'success',
        error: false,
      };
    }
    case WIDGET_TOKEN_REQUEST_ERROR: {
      return {
        ...state,
        token: null,
        tokenStatus: 'error',
        error: true,
      };
    }
    case WIDGET_TOKEN_REQUEST_RESET: {
      return defaultWidgetState;
    }

    default:
      return state;
  }
};
