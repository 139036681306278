import {
  OPPORTUNITIES_REQUEST_RECEIVED,
  UPDATE_OPPORTUNITY_FLOW_TYPE_ERROR,
  UPDATE_OPPORTUNITY_FLOW_TYPE_PENDING,
  UPDATE_OPPORTUNITY_FLOW_TYPE_RECIEVED,
} from '../actions/opportunities/opportunities-action-types';

const defaultState = {
  byId: {},
  allIds: [],
};

const opportunities = (state = defaultState, action) => {
  const results = action.payload;
  switch (action.type) {
    case OPPORTUNITIES_REQUEST_RECEIVED:
      return {
        allIds: results.map((opp) => opp.id),
        byId: results.reduce((result, opp) => {
          result[opp.id] = opp;
          return result;
        }, {}),
      };
    case UPDATE_OPPORTUNITY_FLOW_TYPE_RECIEVED:
      return {
        ...state,
        updateFlowTypeStatus: 'success',
      };
    case UPDATE_OPPORTUNITY_FLOW_TYPE_PENDING:
      return {
        ...state,
        updateFlowTypeStatus: 'pending',
      };
    case UPDATE_OPPORTUNITY_FLOW_TYPE_ERROR:
      return {
        ...state,
        updateFlowTypeStatus: 'error',
      };
    default:
      return state;
  }
};

export default opportunities;
