import accounts from './accounts';
import bank from './bank';
import booleanStrings from './boolean-strings';
import contactUs from './contact-us';
import countryCodes from './countryCodes';
import errors from './errors';
import loan from './loan';
import opportunity from './opportunity';
import passwordLength from './passwordLength';
import regex from './regex';
import states from './states';
import provinces from './canadianProvinces';
import tooltips from './tooltips';
import messages from './messages';
import displayNames from './displayNames';
import { boaLogo, pncLogo } from '../static/images/logos';
import oneTimePayments from './oneTimePayments';
import openBanking from './open-banking';

export default {
  accounts,
  bank,
  booleanStrings,
  contactUs,
  countryCodes,
  errors,
  messages,
  loan,
  opportunity,
  passwordLength,
  provinces,
  regex,
  displayNames,
  states,
  tooltips,
  oneTimePayments,
  openBanking,
  customerServiceHours: '9 a.m. - 5 p.m. ET Monday - Friday',
  customerServiceHoursCA: '9 a.m. - 6 p.m. ET Monday - Friday',
  customerServiceHoursForgiveness: '8 a.m. - 6 p.m. ET Monday - Friday',
  pppServiceHours: '8 a.m. - 6 p.m. ET Monday - Friday',
  pppServicePhone: '(866) 740-0393',
  emailBrand: 'PayPal|LoanBuilder',
  routingWarningConstantObj: {
    '043000096': ['PA', 'OH'],
    '026009593': ['CA'],
  },
  routingWarningBankMapping: {
    '043000096': 'PNC Bank National Association',
    '026009593': 'Bank of America National Association',
  },
  bankDetails: {
    'PNC Bank National Association': { name: 'PNC Bank', logo: pncLogo },
    'Bank of America National Association': { name: 'Bank of America', logo: boaLogo },
  },
  BankFormStipVersion: 'v2',
};
