/* eslint-disable no-unused-vars */
import { useHistory, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  CaptionText, HeadingText, BodyText, LockIcon, Button, CriticalIcon,
} from '@paypalcorp/pp-react';
import PropTypes from 'prop-types';
import resolve from '../../services/route.service';
import routes from '../../routes';
import constants from '../../constants';
import OpenBankIconComponent from '../common/open-banking-icon';
import './open-banking-component.less';

const OpenBankingComponent = ({
  country, groupName, opportunityId, nonTerminalErrorCount, terminalErrorCount,
}) => {
  const history = useHistory();
  const [showErrorAlert, setErrorAlert] = useState(false);

  /**
   * This function redirect to bank upload page
   */
  const redirectToBankUpload = () => {
    const RoutePayload = {
      country,
      opportunityId,
      groupName,
    };
    const routeToRedirect = resolve(routes['upload-document-group'].path, RoutePayload);
    history.push(routeToRedirect);
  };

  /**
   * removes ocrolus iframe from DOM to surface our error page
   * @param {id} id - id of the iframe to be removed
   */
  const removePlaidModal = () => {
    window.postMessage({ type: 'DESTROY_MODAL_IFRAME' }, '*');
  };

  /**
   * error handler to terminate the flow for both terminal and non terminal error
   */
  const terminalErrorHandler = () => {
    setErrorAlert(true);
    removePlaidModal();
  };

  useEffect(() => {
    if (nonTerminalErrorCount > 2 || terminalErrorCount > 0) {
      terminalErrorHandler();
    }
  }, [nonTerminalErrorCount, terminalErrorCount]);

  const TerminalErrorComponent = () => (
    <div
      id="terminal-error-container"
      data-testid="test-open-banking-error-container"
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '.5rem',

      }}
    >
      <div
        id="error-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '1rem',
          width: '50%',
          textAlign: 'center',

        }}
      >
        <CriticalIcon size="xl" color="sysColorErrorMain" />
        <BodyText>
          {constants.openBanking.ocrolusErrorDetails.GENERIC_TERMINAL_ERROR.displayMessage}
        </BodyText>
      </div>

      <div
        id="btn-container"
        style={{
          marginTop: '2rem',

        }}
      >
        <Button
          type="button"
          as={Link}
          to={resolve(routes['upload-document-group'].path, {
            country,
            opportunityId,
            groupName,
          })}
        >
          {constants.openBanking.openBankingChoiceSecondaryBtnText}
        </Button>
      </div>
    </div>
  );

  const OpenBankingComponentDisplay = () => (
    <div id="open-banking-container" data-testid="test-open-banking-container">
      <BodyText>

        <span>   Securely connect your bank account by using Plaid. Or, you can &nbsp;</span>
        <span tabIndex="-1" role="button" style={{ color: '#0070E0', cursor: 'pointer', textDecoration: 'underline' }} onKeyPress={redirectToBankUpload} onClick={redirectToBankUpload}>manually upload PDFs</span>

      </BodyText>

      <div id="ocrolus-widget-frame" />

      <div
        id="caption-text-with-icon"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <LockIcon />
        <CaptionText strong>
          {constants.openBanking.openBankingChoiceCaptionText}
        </CaptionText>
      </div>
    </div>
  );

  return (

    <>
      <div id="open-banking-choice-component" data-testid="test-open-banking-choice-component" className="open-banking-choice-style">
        <div className="open-banking-choice-container">
          <div id="bank-icon-container" className="bank-icon-style">
            <OpenBankIconComponent />
          </div>
          <HeadingText size="sm">
            {constants.openBanking.openBankingChoiceTitleText}
          </HeadingText>
          {
            showErrorAlert === false ? <OpenBankingComponentDisplay />
              : <TerminalErrorComponent />
          }
        </div>
      </div>

    </>

  );
};

OpenBankingComponent.propTypes = {
  country: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  opportunityId: PropTypes.string.isRequired,
  nonTerminalErrorCount: PropTypes.number.isRequired,
  terminalErrorCount: PropTypes.number.isRequired,
};

export default OpenBankingComponent;
