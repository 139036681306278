/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { push as pushAction } from 'connected-react-router';
import resolve from '../services/route.service';
import SecurePage from '../hocs/secure-page';
import openBankingUtils from '../lib/open-banking-utils';
import { getWidgetTokenAction, resetWidgetTokenStatusAction } from '../redux/actions/open-banking/open-banking-actions';
import routes from '../routes';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';
import SpinnerStandaloneComponent from '../components/common/spinner-standalone/spinner-standalone';
import OpenBankingComponent from '../components/open-banking-component/open-banking-component';
import openBankingConstants from '../constants/open-banking';
import { updateOpportunityFlowTypeAction } from '../redux/actions/opportunities/opportunities-actions';
import heapService from '../services/heap.service';

class UploadDocumentOpenBankingPage extends Component {
  static displayName = displayNames.UploadDocumentOpenBankingPage;

  static propTypes = {
    country: PropTypes.string,
    getWidgetToken: PropTypes.func.isRequired,
    resetWidgetTokenStatus: PropTypes.func.isRequired,
    groupName: PropTypes.string,
    opportunityId: PropTypes.string,
    push: PropTypes.func.isRequired,
    widgetTokenStatus: PropTypes.string,
    widgetToken: PropTypes.string,
    widgetTokenError: PropTypes.bool,
    updateOpportunityFlowType: PropTypes.func.isRequired,
    updateOpportunityFlowTypeStatus: PropTypes.string,
  };

  static defaultProps = {
    country: undefined,
    groupName: undefined,
    opportunityId: undefined,
    widgetTokenStatus: undefined,
    widgetToken: undefined,
    widgetTokenError: false,
    updateOpportunityFlowTypeStatus: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      openBankingNonTerminalError: null, openBankingTerminalError: null, terminalErrorCount: 0, nonTerminalErrorCount: 0,
    };
  }

  componentDidMount() {
    const {
      opportunityId,
      getWidgetToken,
      widgetTokenStatus,
      widgetToken,
      widgetTokenError,
    } = this.props;
    getWidgetToken(opportunityId);
    if (widgetTokenStatus === 'success' && widgetToken !== null) {
      // eslint-disable-next-line func-names
      (window).getAuthToken = function () {
        return widgetToken;
      };
    }
    window.addEventListener('message', this.ocrolusEvent);
  }

  componentDidUpdate(previousProps) {
    const {
      widgetTokenStatus,
      widgetToken,
      updateOpportunityFlowTypeStatus,
      opportunityId,
    } = this.props;

    if (widgetTokenStatus !== previousProps.widgetTokenStatus) {
      if (widgetTokenStatus === 'error') {
        this.updateOcrolusErrorState(openBankingConstants.ocrolusErrorDetails.GENERIC_TERMINAL_ERROR.displayMessage,
          openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT);
      }
    }
    if (widgetTokenStatus === 'success' && widgetToken !== null) {
      // eslint-disable-next-line func-names
      (window).getAuthToken = function () {
        return widgetToken;
      };
    }

    if (updateOpportunityFlowTypeStatus !== previousProps.updateOpportunityFlowTypeStatus) {
      if (updateOpportunityFlowTypeStatus === 'error') {
        this.updateOcrolusErrorState(openBankingConstants.ocrolusErrorDetails.GENERIC_TERMINAL_ERROR.displayMessage,
          openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT);
      }

      if (updateOpportunityFlowTypeStatus === 'success') {
        heapService.markOpenBankingStipulationAsComplete(opportunityId);
        this.redirectToSummaryConfirmation();
      }
    }
  }

  componentWillUnmount() {
    const { resetWidgetTokenStatus } = this.props;
    resetWidgetTokenStatus();
    window.removeEventListener('message', this.ocrolusEvent);
  }

  /**
   * This function handle ocrolus/plaid success and redirect to summary
   * @param {object} event - event emitted by ocrolus
   */
  handleOcrolusSuccess = () => {
    const { updateOpportunityFlowType, opportunityId } = this.props;
    updateOpportunityFlowType(opportunityId, openBankingConstants.openBankingStipType);
  }

  /**
   * This function handle ocrolus / plaid error and update error state based on error type
   * @param {object} event - event emitted by ocrolus
   */
  handleOcrolusError = (event) => {
    const errorType = event?.data?.error?.errorType || null;
    if (errorType) {
      const errorCategory = openBankingUtils.getOcrolusErrorCategory(errorType);
      if (errorCategory === null) {
        this.updateOcrolusErrorState(openBankingConstants.ocrolusErrorDetails.GENERIC_TERMINAL_ERROR.displayMessage,
          openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT);
      }
      const errorCode = event?.data?.error?.errorCode || null;
      if (errorCode == null) {
        this.updateOcrolusErrorState(openBankingConstants.ocrolusErrorDetails.GENERIC_TERMINAL_ERROR.displayMessage,
          openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT);
      }
      if (errorCategory === openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT) {
        this.updateOcrolusErrorState(openBankingConstants.ocrolusErrorDetails.GENERIC_TERMINAL_ERROR.displayMessage,
          openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT);
      }
      if (errorCategory === openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_NON_TERMINATING_EVENT) {
        const errorDetails = openBankingUtils.getErrorDetails(errorCode);
        this.updateOcrolusErrorState(errorDetails?.displayMessage,
          openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_NON_TERMINATING_EVENT);
      }
    }
    else {
      this.updateOcrolusErrorState(openBankingConstants.ocrolusErrorDetails.GENERIC_TERMINAL_ERROR.displayMessage,
        openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT);
    }
  }

  /**
   * This function listens to ocrolus event and forward it to respective handlers based on success and error
   * @param {object} event - event emitted by ocrolus
   */
  ocrolusEvent = (event) => {
    const eventStatus = openBankingUtils.getEventStatus(event.data.type);
    if (eventStatus !== null) {
      if (eventStatus === openBankingConstants.ocrolusEventStatus.LINK_SUCCESS) {
        this.handleOcrolusSuccess(event);
      }
      if (eventStatus === openBankingConstants.ocrolusEventStatus.PLAID_ERROR) {
        this.handleOcrolusError(event);
      }
    }
  }

  /**
   * update ocrolus error state
   * @param {string} displayMessage - message to display on error
   * @param {string} errorCategory - error category, (OCROLUS_ERROR_NON_TERMINATING_EVENT | OCROLUS_ERROR_TERMINATING_EVENT)
   */
  updateOcrolusErrorState(displayMessage, errorCategory) {
    const { nonTerminalErrorCount, terminalErrorCount } = this.state;
    if (errorCategory === openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_NON_TERMINATING_EVENT) {
      this.setState({
        openBankingNonTerminalError: displayMessage,
        openBankingTerminalError: null,
        terminalErrorCount,
        nonTerminalErrorCount: nonTerminalErrorCount + 1,
      });
    }
    else if (errorCategory === openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT) {
      this.setState({
        openBankingNonTerminalError: null,
        openBankingTerminalError: displayMessage,
        nonTerminalErrorCount,
        terminalErrorCount: terminalErrorCount + 1,
      });
    }
    else {
      this.setState({
        openBankingNonTerminalError: null,
        openBankingTerminalError: openBankingConstants.ocrolusErrorDetails.GENERIC_TERMINAL_ERROR.displayMessage,
        terminalErrorCount: terminalErrorCount + 1,
      });
    }
  }

  /**
   *  redirect to summary page
   */
  redirectToSummaryConfirmation() {
    const { country, groupName, push } = this.props;
    const RoutePayload = {
      country,
      successStipulationGroupName: groupName,
    };

    push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      groupName, country, opportunityId,
      widgetTokenStatus,
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>{groupName}</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div>
              {
                widgetTokenStatus === 'pending' ? <SpinnerStandaloneComponent /> : <OpenBankingComponent country={country} opportunityId={opportunityId} groupName={groupName} nonTerminalErrorCount={this.state.nonTerminalErrorCount} terminalErrorCount={this.state.terminalErrorCount} />
              }

            </div>
          )}
          sidebarElement={<></>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.router.location,
    widgetToken: state.widgetAccessToken.token,
    widgetTokenStatus: state.widgetAccessToken.tokenStatus,
    widgetTokenError: state.widgetAccessToken.error,
    updateOpportunityFlowTypeStatus: state.entities.opportunities.updateFlowTypeStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetWidgetTokenStatus: () => dispatch(resetWidgetTokenStatusAction()),
    push: (path) => dispatch(pushAction(path)),
    getWidgetToken: (oppId) => dispatch(getWidgetTokenAction(oppId)),
    updateOpportunityFlowType: (oppId, flowType) => dispatch(updateOpportunityFlowTypeAction(oppId, flowType)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  SecurePage,
)(UploadDocumentOpenBankingPage);
