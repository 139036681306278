import axios from 'axios';
import { has } from 'lodash';
import constants from '../constants';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  getRoutingNumberInstitution: asyncDebounce(getRoutingNumberInstitution),
  postSelfServiceAddEditBank: asyncDebounce(postSelfServiceAddEditBank),
  checkRoutingNumberWarningValidation,
  getRoutingNumberInstitutionList,
  getSelfServicePaymentBankList,
  getWidgetToken,
};

function checkRoutingNumberWarningValidation(institution, businessState) {
  if (institution === undefined || institution.fileRoutingNumber === undefined) {
    return false;
  }
  if (institution.fileRoutingNumber === undefined) {
    return false;
  }
  if (!has(constants.routingWarningConstantObj, institution.fileRoutingNumber)) {
    return false;
  }
  const excludedStates = constants.routingWarningConstantObj[institution.fileRoutingNumber];
  if (excludedStates.includes(businessState)) {
    return false;
  }
  return true;
}

async function getRoutingNumberInstitution(apiUrl, routingNumber) {
  const res = await axios.get(`${apiUrl}/bank/${routingNumber}`, { ...defaultRequestOptions(), params: { hideSpinner: true } });
  return res.data;
}

async function getRoutingNumberInstitutionList(apiUrl) {
  const res = await axios.get(`${apiUrl}/bank/getlist/routingNumberList`, { ...defaultRequestOptions(), params: { hideSpinner: true } });
  return res.data;
}

async function getSelfServicePaymentBankList(apiUrl, advanceId) {
  const res = await axios.get(`${apiUrl}/bank/${advanceId}/selfServicePaymentBankList`, { ...defaultRequestOptions(), params: { hideSpinner: true } });
  return res.data;
}

async function postSelfServiceAddEditBank(apiUrl, requestPayload) {
  const url = `${apiUrl}/bank/addEditSelfServiceBankInfo`;
  const res = await axios.post(url, requestPayload, { ...defaultRequestOptions(), params: { hideSpinner: true } });
  return res.data;
}

async function getWidgetToken(apiUrl, opportunityId) {
  const url = `${apiUrl}/bank/${opportunityId}/getWidgetToken`;
  const res = await axios.post(url, {}, { ...defaultRequestOptions(), params: { hideSpinner: true } });
  return res.data;
}
