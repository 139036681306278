import axios from 'axios';
import moment from 'moment';
import constants from '../constants';
import partnerNames from '../constants/partner';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

function shouldShowStatusTracker(opp) {
  if (opp.stageName !== 'Closed Won' && opp.stageName !== 'Closed Lost') {
    return true;
  }
  if (!opp.closedDate || opp.type.toLowerCase() === 'card') {
    return false;
  }
  const now = moment();
  const closedDate = moment.utc(opp.closedDate);
  const hoursSinceClose = moment.duration(now.diff(closedDate)).asHours();

  return hoursSinceClose < constants.opportunity.statusTrackerHoursAfterOppClose;
}

async function fulfillStipulation(apiUrl, opportunityId, stipulationId) {
  const url = `${apiUrl}/v2/opportunities/${opportunityId}/stipulations/${stipulationId}/fulfillStipulation`;
  const res = await axios.post(url, {}, defaultRequestOptions());
  return res.data;
}

async function getBfes(apiUrl, opportunities) {
  const filteredOpportunities = opportunities.filter(
    (opp) => opp.opportunity.partner !== partnerNames.fundera && opp.opportunity.partner !== partnerNames.lendio,
  );
  const promises = filteredOpportunities.map((opp) => debouncedGetBfe(apiUrl, opp.opportunity.id));
  return Promise.all(promises);
}
const debouncedGetBfes = asyncDebounce(getBfes);

async function getBfe(apiUrl, id) {
  const url = `${apiUrl}/opportunities/${id}/business-funding-experts`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetBfe = asyncDebounce(getBfe);

async function getContacts(apiUrl, id) {
  const url = `${apiUrl}/opportunities/${id}/contacts`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetContacts = asyncDebounce(getContacts);

async function getLoanAgreementStatus(apiUrl, id) {
  const url = `${apiUrl}/opportunities/${id}/loan-agreement-status`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetLoanAgreementStatus = asyncDebounce(getLoanAgreementStatus);

async function getOpportunities(apiUrl) {
  const url = `${apiUrl}/v2/opportunities`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetOpportunities = asyncDebounce(getOpportunities);

async function getAdvances(apiUrl, id) {
  const url = `${apiUrl}/v2/opportunities/${id}/advances`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetAdvances = asyncDebounce(getAdvances);

async function getStipulations(apiUrl, id, country) {
  const url = `${apiUrl}/v2/opportunities/${id}/${country}/stipulations`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetStipulations = asyncDebounce(getStipulations);

async function getStipulationsV2(apiUrl, id) {
  const url = `${apiUrl}/v2/opportunities/${id}/stipulations`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetStipulationsV2 = asyncDebounce(getStipulationsV2);

async function setAsOwner(apiUrl, id) {
  const url = `${apiUrl}/opportunities/${id}/owns-property`;
  const res = await axios.post(url, {}, defaultRequestOptions());
  return res.data;
}

async function getBankInformation(apiUrl, id) {
  const res = await axios.get(`${apiUrl}/opportunities/${id}/bank-accounts`, defaultRequestOptions());
  return res.data;
}
const debouncedGetBankInformation = asyncDebounce(getBankInformation);

async function saveBankInformation(apiUrl, id, bankInfo) {
  const res = await axios.post(`${apiUrl}/opportunities/${id}/bank-accounts`, bankInfo, defaultRequestOptions());
  return res.data;
}

async function addBankInformation(apiUrl, id, bankInfo) {
  const res = await axios.post(`${apiUrl}/opportunities/${id}/add-bank-accounts`, bankInfo, defaultRequestOptions());
  return res.data;
}

async function getNewStipulationFlag(apiUrl, id) {
  const res = await axios.get(`${apiUrl}/v2/opportunities/${id}/isStipulationEnabled`, defaultRequestOptions());
  return res.data;
}
const debouncedGetNewStipulationFlag = asyncDebounce(getNewStipulationFlag);

async function updateFlowType(apiUrl, id, flowType) {
  const res = await axios.post(`${apiUrl}/opportunities/${id}/flow-type`, { flowType }, defaultRequestOptions());
  return res.data;
}

export default {
  shouldShowStatusTracker,
  fulfillStipulation,
  getAdvances: debouncedGetAdvances,
  getBfes: debouncedGetBfes,
  getContacts: debouncedGetContacts,
  getLoanAgreementStatus: debouncedGetLoanAgreementStatus,
  getOpportunities: debouncedGetOpportunities,
  getStipulations: debouncedGetStipulations,
  getStipulationsV2: debouncedGetStipulationsV2,
  setAsOwner,
  getBankInformation: debouncedGetBankInformation,
  getNewStipulationFlag: debouncedGetNewStipulationFlag,
  saveBankInformation,
  addBankInformation,
  updateFlowType,
};
