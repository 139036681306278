import openBankingConstants from '../constants/open-banking';

const errorCategoryMapping = {
  OCROLUS_ERROR_TERMINATING_EVENT: ['OCROLUS_ERROR', 'INSTITUTION_ERROR', 'PLANNED_MAINTENANCE', 'INVALID_SEND_METHOD', 'MFA_NOT_SUPPORTED', 'INSTITUTION_DOWN', 'INSTITUTION_NOT_RESPONDING', 'INSTITUTION_NOT_AVAILABLE', 'INSTITUTION_NO_LONGER_SUPPORTED', 'UNSUPPORTED_RESPONSE', 'FAILED_TO_FETCH_LINK_TOKEN', 'FAILED_TO_FETCH_ACCESS_TOKEN'],
  OCROLUS_ERROR_NON_TERMINATING_EVENT: ['USER_ERROR', 'INSUFFICIENT_CREDENTIALS', 'INVALID_CREDENTIALS', 'INVALID_MFA', 'INVALID_OTP', 'ITEM_LOCKED', 'USER_INPUT_TIMEOUT'],
};

/**
 * Returns ocrolus event status after the open banking flow,it can be either a success or a linking failure
 * @param {string} [ocrolusEventType] start date
 * @returns {string|null} type of event based on the flow
 */

function getEventStatus(ocrolusEventType) {
  if (ocrolusEventType === openBankingConstants.ocrolusEventStatus.LINK_SUCCESS) {
    return openBankingConstants.ocrolusEventStatus.LINK_SUCCESS;
  }
  if (ocrolusEventType === openBankingConstants.ocrolusEventStatus.PLAID_ERROR) {
    return openBankingConstants.ocrolusEventStatus.PLAID_ERROR;
  }
  return null;
}

/**
 * Determines the category of a given Ocrolus error type.
 *
 * @param {string} ocrolusErrorType - The type of Ocrolus error to categorize.
 * @returns {string|null} - The error category constant (either non-terminating or terminating)
 *                          from `openBankingConstants.ocrolusErrorEventCategory`, or `null` if no match is found.
 */

function getOcrolusErrorCategory(ocrolusErrorType) {
  if (errorCategoryMapping.OCROLUS_ERROR_NON_TERMINATING_EVENT.includes(ocrolusErrorType)) {
    return openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_NON_TERMINATING_EVENT;
  }
  if (errorCategoryMapping.OCROLUS_ERROR_TERMINATING_EVENT.includes(ocrolusErrorType)) {
    return openBankingConstants.ocrolusErrorEventCategory.OCROLUS_ERROR_TERMINATING_EVENT;
  }
  return null;
}

/**
 * Determines the error details due on linking failure based on error code
 *
 * @param {string} errorCode - The code of Ocrolus error
 * @returns {object|null} - error details based on ocrolusErrorDetails mapping
 *
 */

function getErrorDetails(errorCode) {
  if (openBankingConstants.ocrolusErrorDetails[errorCode]) {
    const errorDetails = openBankingConstants.ocrolusErrorDetails[errorCode];
    return errorDetails;
  }
  return null;
}

export default {
  getEventStatus,
  getOcrolusErrorCategory,
  getErrorDetails,
};
