const jwt = require('jsonwebtoken');

const heapService = {
  setUserInformation,
  markUserStipulationAsCompleted,
  setMissingDataEvent,
  markOpenBankingStipulationAsComplete,
};

function setUserInformation(jwtToken) {
  if (!jwtToken) {
    return;
  }

  const decodedToken = jwt.decode(jwtToken);

  if (decodedToken && decodedToken.guid) {
    window.heap.identify(decodedToken.guid.toString());
  }
}

function markUserStipulationAsCompleted(oppId, stipName) {
  if (!oppId || !stipName) {
    return;
  }

  window.heap.track('portal_stipulation_complete', {
    opportunityId: oppId,
    stipulationName: stipName,
  });
}

function setMissingDataEvent(loanforgivenessObj) {
  if (!loanforgivenessObj) {
    return;
  }
  const fieldsMissing = '';
  if (!loanforgivenessObj.fteReductionQuotient) {
    fieldsMissing.concat('FTERedQuotient__c ');
  }

  if (!loanforgivenessObj.totalPayrollCost) {
    fieldsMissing.concat('TotPayrollCosts__c ');
  }

  if (!loanforgivenessObj.totalEligibleExpenses) {
    fieldsMissing.concat('TotEligibleExpenses8Weeks__c ');
  }

  if (!loanforgivenessObj.payrollCost60Percent) {
    fieldsMissing.concat('pctofPayrollRQMT__c ');
  }

  window.heap.track('missing_forgiveness_data', {
    fieldsMissing,
    forgivenessId: loanforgivenessObj.id,
  });
}

function markOpenBankingStipulationAsComplete(oppId) {
  if (!oppId) {
    return;
  }

  window.heap.track('open_banking_stipulation_complete', {
    opportunityId: oppId,
    stipulationName: 'open_banking',
  });
}

export default heapService;
