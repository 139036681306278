import opportunityService from '../../../services/opportunity.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './opportunities-action-types';

export const getOpportunitiesAction = asyncAuthorizedActionCreator(
  {
    pending: actions.OPPORTUNITIES_REQUEST_PENDING,
    complete: actions.OPPORTUNITIES_REQUEST_RECEIVED,
    error: actions.OPPORTUNITIES_REQUEST_ERROR,
  },
  () => (
    dispatch,
    getState,
  ) => opportunityService.getOpportunities(getState().config.apiUrl),
);

export const updateOpportunityFlowTypeAction = asyncAuthorizedActionCreator(
  {
    pending: actions.UPDATE_OPPORTUNITY_FLOW_TYPE_PENDING,
    complete: actions.UPDATE_OPPORTUNITY_FLOW_TYPE_RECIEVED,
    error: actions.UPDATE_OPPORTUNITY_FLOW_TYPE_ERROR,
  },
  (oppId, flowType) => (
    dispatch,
    getState,
  ) => opportunityService.updateFlowType(getState().config.apiUrl, oppId, flowType),
);
