/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import SecurePage from '../hocs/secure-page';
import OpenBankingChoiceComponent from '../components/open-banking-choice/open-banking-choice';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

class UploadDocumentChoicePage extends Component {
  static displayName = displayNames.UploadDocumentChoicePage;

  static propTypes = {
    country: PropTypes.string,
    groupName: PropTypes.string,
    opportunityId: PropTypes.string,
  };

  static defaultProps = {
    country: undefined,
    groupName: undefined,
    opportunityId: undefined,
  };

  render() {
    const {
      groupName, country, opportunityId,
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>{groupName}</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div>
              <OpenBankingChoiceComponent country={country} opportunityId={opportunityId} groupName={groupName} />

            </div>
          )}
          sidebarElement={<></>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.router.location,
  };
}

export default compose(
  connect(mapStateToProps),
  SecurePage,
)(UploadDocumentChoicePage);
