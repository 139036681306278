export default {
  AddBankAccountsPage: 'AddBankAccountsPage',
  AddBusinessWebAddressPage: 'AddBusinessWebAddressPage',
  AddHomePhonePage: 'AddHomePhonePage',
  AdditionalIdentificationPage: 'AdditionalIdentificationPage',
  ActionItemsPage: 'ActionItemsPage',
  ActivityPage: 'ActivityPage',
  BusinessOwnershipPage: 'BusinessOwnershipPage',
  CalculatePayrollDataPage: 'CalculatePayrollDataPage',
  ChangeEmailPage: 'ChangeEmailPage',
  ChangePasswordPage: 'ChangePasswordPage',
  ChangeSecurityQuestionsPage: 'ChangeSecurityQuestionsPage',
  ConfirmPaypalAccountPage: 'ConfirmPaypalAccountPage',
  ConfirmPaypalAccountMigratedFlowPage: 'ConfirmPaypalAccountMigratedFlowPage',
  ContactUsPage: 'ContactUsPage',
  CoveredCostsDocumentsPage: 'CoveredCostsDocumentsPage',
  CreateAccountPage: 'CreateAccountPage',
  DemographicInformationPage: 'DemographicInformationPage',
  DriversLicensePage: 'DriversLicensePage',
  ForgivenessApplicationPage: 'ForgivenessApplicationPage',
  ForgivenessGrossReceiptsPage: 'ForgivenessGrossReceiptsPage',
  ForgivenessGrossReceiptsDocumentsPage: 'ForgivenessGrossReceiptsDocumentsPage',
  ForgivenessCustomerSignoffPage: 'ForgivenessCustomerSignoffPage',
  ForgivenessDetailPage: 'ForgivenessDetailPage',
  ForgivenessSignoffCompletedPage: 'ForgivenessSignoffCompletedPage',
  ForgivenessSummaryPage: 'ForgivenessSummaryPage',
  ForgotPasswordPage: 'ForgotPasswordPage',
  IdentificationPage: 'IdentificationPage',
  LoginPage: 'LoginPage',
  MaintenancePage: 'MaintenancePage',
  OAuthLandingPage: 'OAuthLandingPage',
  OAuthLandingPageMigratedFlow: 'OAuthLandingPageMigratedFlow',
  OAuthPayPalPage: 'OAuthPayPalPage',
  OAuthPayPalPageMigratedFlow: 'OAuthPayPalPageMigratedFlow',
  OwnershipPage: 'OwnershipPage',
  PayrollVerificationPage: 'PayrollVerificationPage',
  QuestionnairePage: 'QuestionnairePage',
  UploadLoanForgivenessPage: 'UploadLoanForgivenessPage',
  PrivacyPolicyPage: 'PrivacyPolicyPage',
  ProofOfBusinessPage: 'ProofOfBusinessPage',
  UploadDocumentGroupPage: 'UploadDocumentGroupPage',
  UploadDocumentChoicePage: 'UploadDocumentChoicePage',
  UploadDocumentOpenBankingPage: 'UploadDocumentOpenBankingPage',
  RedirectPage: 'RedirectPage',
  ReductionInGrossReceiptsDocumentsPage: 'ReductionInGrossReceiptsDocumentsPage',
  ReferencesPage: 'ReferencesPage',
  ResetAccountPage: 'ResetAccountPage',
  ResetPasswordPage: 'ResetPasswordPage',
  ReductionInGrossReceiptsPage: 'ReductionInGrossReceiptsPage',
  Sba3508SFormPage: 'Sba3508SFormPage',
  SecurityQuestionPage: 'SecurityQuestionPage',
  SecurityQuestionsPage: 'SecurityQuestionsPage',
  SettingsPage: 'SettingsPage',
  SummaryPage: 'SummaryPage',
  OneTimePaymentPage: 'OneTimePaymentPage',
  MakeAPayment: 'MakeAPayment',
  AddBusinessInfo: 'AddBusinessInfo',
  AddBankInfo: 'AddBankInfo',
  ChangePaymentMethod: 'ChangePaymentMethod',
  PaymentConfirmation: 'PaymentConfirmation',
  PaymentAuthorization: 'PaymentAuthorization',
  TermsOfUsePage: 'TermsOfUsePage',
  TermsAndConditionsPage: 'TermsAndConditionsPage',
  UploadCanadianBankStatementsPage: 'UploadCanadianBankStatementsPage',
  VerifyBankAccountsPage: 'VerifyBankAccountsPage',
  VerifyEmailPage: 'VerifyEmailPage',
  UploadAddendumAPage: 'UploadAddendumAPage',
  UploadAddendumBPage: 'UploadAddendumBPage',
  BusinessLicensePage: 'BusinessLicensePage',
  ProofOfIdentityPage: 'ProofOfIdentityPage',
  TaxReturnPage: 'TaxReturnPage',
  VoidedCheckPage: 'VoidedCheckPage',
};
