import React from 'react';
import {
  BankInfoIcon,
} from '@paypalcorp/pp-react';

const OpenBankIconComponent = () => {
  const EllipsisIconComponent = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6" fill="none">
      <circle cx="2.5" cy="3" r="2.5" fill="#545D68" />
    </svg>
  );

  const PayPalComponent = () => (
    <img
      src="https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg"
      alt="paypal-logo"
      style={{ width: '3.75rem' }}
    />
  );

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '12px',
    }}
    >

      <BankInfoIcon size="xl" color="sysColorAccent1Main" />
      <EllipsisIconComponent />
      <EllipsisIconComponent />
      <EllipsisIconComponent />
      <EllipsisIconComponent />
      <PayPalComponent />
    </div>
  );
};

export default OpenBankIconComponent;
