import bankService from '../../../services/bank.service';

import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './open-banking-action-types';

export const getWidgetTokenAction = asyncAuthorizedActionCreator(
  {
    pending: actions.WIDGET_TOKEN_REQUEST_PENDING,
    complete: actions.WIDGET_TOKEN_REQUEST_RECEIVED,
    error: actions.WIDGET_TOKEN_REQUEST_ERROR,
  },
  (oppId) => (
    _dispatch,
    getState,
  ) => bankService.getWidgetToken(getState().config.apiUrl, oppId),
);

export const resetWidgetTokenStatusAction = () => (dispatch) => dispatch({ type: actions.WIDGET_TOKEN_REQUEST_RESET });
