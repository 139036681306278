export default {
  openBankingChoiceTitleText: ' We need to review the past 4 months of your bank statements',
  openBankingChoiceBodyText: ' Securely connect your bank account by using Plaid or manually upload your PDF statements.',
  openBankingChoiceCaptionText: 'We will never access or store your banking login information',
  openBankingChoicePrimaryBtnText: 'Securely connect my bank',
  openBankingChoiceSecondaryBtnText: 'Upload  PDF  statments',
  ocrolusErrorEventCategory: {
    OCROLUS_ERROR_TERMINATING_EVENT: 'OCROLUS_ERROR_TERMINATING_EVENT',
    OCROLUS_ERROR_NON_TERMINATING_EVENT: 'OCROLUS_ERROR_NON_TERMINATING_EVENT',
  },
  ocrolusEventStatus: {
    LINK_SUCCESS: 'LINK_SUCCESS',
    PLAID_ERROR: 'PLAID_ERROR',
  },
  ocrolusErrorDetails: {
    GENERIC_TERMINAL_ERROR: {
      errorCode: 'GENERIC_TERMINAL_ERROR',
      displayMessage: 'There was a technical error. Please upload PDF copies of your bank statements to proceed',
    },
    GENERIC_NON_TERMINAL_ERROR: {
      errorCode: 'GENERIC_NON_TERMINAL_ERROR',
      displayMessage: 'There was a technical error. Please try again.',
    },
    INTERNAL_SERVER_ERROR: {
      errorCode: 'INTERNAL_SERVER_ERROR',
      displayMessage: null,
    },
    PLANNED_MAINTENANCE: {
      errorCode: 'PLANNED_MAINTENANCE',
      displayMessage: null,
    },
    INSUFFICIENT_CREDENTIALS: {
      errorCode: 'INSUFFICIENT_CREDENTIALS',
      displayMessage: 'INSUFFICIENT_CREDENTIALS',
    },
    INVALID_CREDENTIALS: {
      errorCode: 'INVALID_CREDENTIALS',
      displayMessage: 'The provided credentials were not correct. Please try again.',
    },
    INVALID_MFA: {
      errorCode: 'INVALID_MFA',
      displayMessage: 'The provided MFA responses were not correct. Please try again.',
    },
    INVALID_OTP: {
      errorCode: 'INVALID_OTP',
      displayMessage: 'The provided OTP was invalid. Please try again.',
    },
    ITEM_LOCKED: {
      errorCode: 'ITEM_LOCKED',
      displayMessage: `The given account has been locked by the financial institution. Please visit your financial
institution's website to unlock your account.`,
    },
    USER_INPUT_TIMEOUT: {
      errorCode: 'USER_INPUT_TIMEOUT',
      displayMessage: 'The application timed out waiting for user input',
    },
    INVALID_SEND_METHOD: {
      errorCode: 'INVALID_SEND_METHOD',
      displayMessage: null,
    },
    MFA_NOT_SUPPORTED: {
      errorCode: 'MFA_NOT_SUPPORTED',
      displayMessage: 'The multi-factor security features enabled on this account are not currently supported for this financial institution. We apologize for the inconvenience.',
    },
    INSTITUTION_DOWN: {
      errorCode: 'INSTITUTION_DOWN',
      displayMessage: 'This financial institution is not currently responding to requests. We apologize for the inconvenience.',
    },
    INSTITUTION_NOT_RESPONDING: {
      errorCode: 'INSTITUTION_NOT_RESPONDING',
      displayMessage: 'This financial institution is not currently responding to requests. We apologize for the inconvenience.',
    },
    INSTITUTION_NOT_AVAILABLE: {
      errorCode: 'INSTITUTION_NOT_AVAILABLE',
      displayMessage: 'This financial institution is not currently responding to requests. We apologize for the inconvenience.',
    },
    INSTITUTION_NO_LONGER_SUPPORTED: {
      errorCode: 'INSTITUTION_NO_LONGER_SUPPORTED',
      displayMessage: 'This financial institution is no longer supported. We apologize for the inconvenience.',
    },
    UNSUPPORTED_RESPONSE: {
      errorCode: 'UNSUPPORTED_RESPONSE',
      displayMessage: 'This financial institution is no longer supported. We apologize for the inconvenience.',
    },
  },
  openBankingStipType: 'AGGREGATOR_ASSET_REPORT',
};
