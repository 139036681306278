import { Link } from 'react-router-dom';
import React from 'react';
import {
  Button, CaptionText, HeadingText, BodyText, LockIcon,
} from '@paypalcorp/pp-react';
import PropTypes from 'prop-types';
import resolve from '../../services/route.service';
import routes from '../../routes';
import constants from '../../constants';
import OpenBankIconComponent from '../common/open-banking-icon';
import './open-banking-choice.less';

const OpenBankingChoiceComponent = ({ country, groupName, opportunityId }) => (
  <>
    <div div="open-banking-choice-component" className="open-banking-choice-style">
      <div className="open-banking-choice-container">
        <div id="bank-icon-container" className="bank-icon-style">
          <OpenBankIconComponent />
        </div>

        <HeadingText size="sm">
          {constants.openBanking.openBankingChoiceTitleText}
        </HeadingText>
        <BodyText>
          {constants.openBanking.openBankingChoiceBodyText}
        </BodyText>
        <div
          id="caption-text-with-icon"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <LockIcon />
          <CaptionText strong>
            {constants.openBanking.openBankingChoiceCaptionText}
          </CaptionText>
        </div>

      </div>

      <div
        id="grouped-btn-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '.5rem',
          marginTop: '3.75rem',

        }}
      >
        <Button
          type="button"
          as={Link}
          to={resolve(routes['upload-document-open-banking'].path, {
            country,
            opportunityId,
            groupName,
          })}
        >
          {constants.openBanking.openBankingChoicePrimaryBtnText}
        </Button>
        <Button
          type="button"
          as={Link}
          secondary
          to={resolve(routes['upload-document-group'].path, {
            country,
            opportunityId,
            groupName,
          })}
        >
          {constants.openBanking.openBankingChoiceSecondaryBtnText}
        </Button>
      </div>
    </div>

  </>
);

OpenBankingChoiceComponent.propTypes = {
  country: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  opportunityId: PropTypes.string.isRequired,
};
export default OpenBankingChoiceComponent;
